import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { ResponsiveContainer, Bar, BarChart, Tooltip as reTooltip, XAxis } from 'recharts'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

const PREFIX = 'StoolBarChartWidget';

const classes = {
    paperHeader: `${PREFIX}-paperHeader`,
    miniIcon: `${PREFIX}-miniIcon`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.paperHeader}`]: {
		padding: '10px',
		boxShadow:
			'0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
		// margin: '-20px 15px 0',
		borderRadius: 3,
		padding: 15,
		marginBottom: 0,
		position: 'relative',
	},

    [`& .${classes.miniIcon}`]: {
		// color: red600,
		marginRight: '10px',
	}
}));

function StoolBarChartWidget(props) {
	const { } = props

	return (
        <StyledPaper>
			<div className={classes.paperHeader}>
				<div>
					<ResponsiveContainer minHeight={130}>
						<BarChart data={props.data}>
							<Bar dataKey="uv" fill={'yellow'} />
							<XAxis dataKey="name" stroke="none" tick={{ fill: 'white' }} />
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
			<div className="card-content">
				<h4 className="title">Completed Tasks</h4>
				<p className="category">Daily Completed Tasks Graphic</p>
			</div>
			<div className="card-footer">
				<div className="stats">
					<i className={classes.miniIcon}>access_time</i>
					updated 4 minutes ago
				</div>
			</div>
		</StyledPaper>
    );
}

export default compose( withTranslation())(StoolBarChartWidget)
