import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'

import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _Context from './_Context'

const PREFIX = 'InterventionComparison'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	block: `${PREFIX}-block`,
	item: `${PREFIX}-item`,
	itemTitle: `${PREFIX}-itemTitle`,
	itemSubTitle: `${PREFIX}-itemSubTitle`,
	searchBar: `${PREFIX}-searchBar`,
	searchBarTop: `${PREFIX}-searchBarTop`,
	compactSearch: `${PREFIX}-compactSearch`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		// margin: 'auto',
		// overflow: 'hidden',
		// paddingLeft: 15,
		// paddingRight: 15,
	},

	[`& .${classes.paper}`]: {
		padding: 10,
	},
	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	[`& .${classes.block}`]: {
		justifyContent: 'center',
		verticalAlign: 'middle',
	},
	[`& .${classes.searchBarTop}`]: {
		marginBottom: theme.spacing(1.5),
	},
	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},

	[`& .${classes.contentWrapper}`]: {
		padding: 20,
	},

	[`& .${classes.item}`]: {
		padding: 15,
	},
	[`& .${classes.itemTitle}`]: {
		fontSize: 16,
	},
	[`& .${classes.itemSubTitle}`]: {
		fontSize: 13,
	},
}))

class InterventionComparison extends Component {
	static contextType = _Context

	constructor(props) {
		super(props)
	}

	static getDerivedStateFromProps(props, state) {
		return state
	}

	state = {}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}
	componentDidMount = () => {}

	render() {
		const { bowel, analytics } = this.props

		return (
			<Root className={classes.root}>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Paper>
							<div style={{ width: '100%', height: 250 }}>Characteristic Distribution</div>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper></Paper>
					</Grid>
				</Grid>
			</Root>
		)
	}
}

InterventionComparison.propTypes = {
	analytics: PropTypes.shape({
		stool_labels_timeseries: PropTypes.shape({
			lastFetched: PropTypes.number,
			data: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchStoolLabels: PropTypes.func.isRequired,
	getBowels: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics, bowel, intervention }) => {
	return {
		analytics,
		bowel,
		intervention,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(InterventionComparison)
