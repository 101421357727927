import React from 'react'

const _Context = React.createContext(null)

class _Provider extends React.Component {
	constructor() {
		super()
		this.state = {
			interventions: [],
		}
	}

	addIntervention = (intervention) => {
		const newList = new Set(this.state.interventions)
		newList.add(intervention)
		this.setState({
			interventions: Array.from(newList),
		})
	}

	clearInterventions = () => {
		this.setState({
			interventions: [],
		})
	}

	render() {
		return (
			<_Context.Provider
				value={{
					interventions: this.state.interventions,
					clearInterventions: this.clearInterventions,
					addIntervention: this.addIntervention,
				}}
			>
				{this.props.children}
			</_Context.Provider>
		)
	}
}

export default _Context

export { _Provider }
