import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'

import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { SafeWorstTable } from '@components'

class SafeWorstTriggers extends Component {
	constructor(props) {
		super(props)
	}

	// static getDerivedStateFromProps(props, state) {
	// 	return {
	// 		...state,
	// 		currentDay: moment(props.summary.attentionDate).format('YYYY-MM-DD'),
	// 		currentDate: props.summary.attentionDate,
	// 		groupedByDate: props.summary.daysEvents,
	// 	}
	// }

	state = {}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}

	render() {
		const { analytics, title, tooltip, triggers, defaultOrder, colorRange } = this.props

		return (
			<SafeWorstTable
				title={title}
				tooltip={tooltip}
				triggers={triggers}
				defaultOrder={defaultOrder}
				colorRange={colorRange}
			/>
		)
	}
}

SafeWorstTriggers.propTypes = {
	analytics: PropTypes.shape({
		safeWorst: PropTypes.shape({
			lastFetched: PropTypes.number,
			best: PropTypes.array,
			worst: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchSafeWorst: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics }) => {
	return {
		analytics,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(SafeWorstTriggers)
