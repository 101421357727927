import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { ResponsiveContainer, Line, LineChart, Tooltip as reTooltip, XAxis } from 'recharts'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

const PREFIX = 'StoolDistributionWidget';

const classes = {
    paperHeader: `${PREFIX}-paperHeader`,
    miniIcon: `${PREFIX}-miniIcon`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.paperHeader}`]: {
		margin: 'auto',
		overflow: 'hidden',
		padding: '10px',
		boxShadow:
			'0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
		margin: '-20px 15px 0',
		borderRadius: 3,
		padding: 15,
	},

    [`& .${classes.miniIcon}`]: {
		// color: red600,
		marginRight: '10px',
	}
}));

function StoolDistributionWidget(props) {
	const { } = props
	let metrics = {}
	if (props.data.length) {
		metrics = Object.keys(props.data[0])
	}

	console.log(props.data)

	return (
        <StyledPaper>
			<div className={classes.paperHeader}>
				<div className={classes.paper}>
					<ResponsiveContainer minHeight={230}>
						<LineChart data={props.data}>
							{/* {Object.keys(metrics).map((metric) => (
								<Line
									key={metric}
									type="monotone"
									dataKey={metric}
									stroke={'purple'}
									strokeWidth={3}
									dot={{
										fill: 'purple',
									}}
								/>
							))} */}
							<Line
								key={'bristol'}
								type="monotone"
								dataKey={'bristol'}
								stroke={'purple'}
								strokeWidth={3}
								dot={{
									fill: 'purple',
								}}
							/>
							<Line
								key={'consistency'}
								type="monotone"
								dataKey={'consistency'}
								stroke={'red'}
								strokeWidth={3}
								dot={{
									fill: 'red',
								}}
							/>
							<Line
								key={'fragmentation'}
								type="monotone"
								dataKey={'fragmentation'}
								stroke={'blue'}
								strokeWidth={3}
								dot={{
									fill: 'blue',
								}}
							/>
							<Line
								key={'volume'}
								type="monotone"
								dataKey={'volume'}
								stroke={'orange'}
								strokeWidth={3}
								dot={{
									fill: 'orange',
								}}
							/>
							<XAxis dataKey="name" hide={true} />
							<reTooltip
								wrapperStyle={{
									width: 120,
									backgroundColor: '#403F4C',
									borderRadius: 5,
								}}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
			<div className="card-content">
				<h4 className="title">Completed Tasks</h4>
				<p className="category">Daily Completed Tasks Graphic</p>
			</div>
			<div className="card-footer">
				<div className="stats">
					<i className={classes.miniIcon}>access_time</i>
					updated 4 minutes ago
				</div>
			</div>
		</StyledPaper>
    );
}

export default compose( withTranslation())(StoolDistributionWidget)
