import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Typography from '@mui/material/Typography'
import Content from '@components/Content'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import InterventionComparison from './InterventionComparison'
import InterventionSummary from './InterventionSummary'
import { _Provider } from './_Context'

const PREFIX = 'StoolInterventionAnalytics'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	roleContainer: `${PREFIX}-roleContainer`,
	searchBarTop: `${PREFIX}-searchBarTop`,
	container: `${PREFIX}-container`,
	loadingContainer: `${PREFIX}-loadingContainer`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	tableWrapper: `${PREFIX}-tableWrapper`,
	compactSearch: `${PREFIX}-compactSearch`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.paper}`]: {
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.searchBarTop}`]: {
		marginBottom: '24px',
	},

	[`& .${classes.loadingContainer}`]: {
		padding: '5px 36px',
	},

	[`& .${classes.contentWrapper}`]: {
		marginTop: '24px',
	},

	[`& .${classes.tableWrapper}`]: {
		padding: '0 16px',
	},

	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}))

class StoolInterventionAnalytics extends Component {
	constructor(props) {
		super(props)
	}

	state = {}

	componentDidMount = () => {}

	render() {
		const { analytics, intervention } = this.props

		return (
			<Root className={classes.root}>
				<_Provider>
					<Stack spacing={2}>
						<InterventionComparison />

						<InterventionSummary />
					</Stack>
				</_Provider>
				{/* <Grid container>
					<Grid
						item
						xs={12}
						style={{
							textAlign: 'end',
						}}
					>
						<Typography variant="overline" display="block">
							Last Fetched: {moment(lastFetched).calendar()}
						</Typography>
					</Grid>
				</Grid> */}

				{/* <Grid container spacing={2} style={{ marginTop: 10 }}>
						
					</Grid> */}
				<Grid container spacing={2} style={{ marginTop: 10 }}>
					<Grid item xs={12}>
						<div>Here 3</div>
					</Grid>
				</Grid>
			</Root>
		)
	}
}

StoolInterventionAnalytics.propTypes = {
	analytics: PropTypes.shape({
		stool_labels_timeseries: PropTypes.shape({
			lastFetched: PropTypes.number,
			data: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
			loading: PropTypes.shape({
				create: PropTypes.bool.isRequired,
				read: PropTypes.bool.isRequired,
				update: PropTypes.bool.isRequired,
				delete: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchStoolLabels: PropTypes.func.isRequired,
	fetchInterventionCompliance: PropTypes.func.isRequired,
	fetchInterventions: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics, intervention }) => {
	return {
		analytics,
		intervention,
	}
}

export default compose(connect(mapStateToProps, actions))(StoolInterventionAnalytics)
