import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import Fuse from 'fuse.js'
import { LinearProgress } from '@components'
import Content from '@components/Content'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import SafeWorstTriggers from './SafeWorstTriggers'
import { cacheDuration } from '../../../../environment'

const PREFIX = 'SafeWorstTriggersContent'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	block: `${PREFIX}-block`,
	searchBar: `${PREFIX}-searchBar`,
	searchBarTop: `${PREFIX}-searchBarTop`,
	container: `${PREFIX}-container`,
	loadingContainer: `${PREFIX}-loadingContainer`,
	tableWrapper: `${PREFIX}-tableWrapper`,
	compactSearch: `${PREFIX}-compactSearch`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.paper}`]: {
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	[`& .${classes.block}`]: {
		justifyContent: 'center',
		verticalAlign: 'middle',
	},

	[`& .${classes.searchBarTop}`]: {
		marginBottom: '24px',
	},

	[`&.${classes.container}`]: {
		padding: '36px 36px',
	},
	[`& .${classes.loadingContainer}`]: {
		padding: '5px 36px',
	},
	[`& .${classes.tableWrapper}`]: {
		padding: '0 16px',
	},

	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}))

class AnalyticsContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {
		searchVal: '',
	}

	componentDidMount = () => {
		const lastFetched = this.props.analytics.safeWorst.lastFetched
		if (!lastFetched) {
			const data = [
				...(this.props.analytics.safeWorst?.best || []),
				...(this.props.analytics.safeWorst?.worst || []),
			]
			if (!data.length) {
				this.hydrateData()
			}
		} else if (lastFetched + cacheDuration <= new Date().getTime()) {
			this.hydrateData()
		}
	}

	hydrateData = () => {
		if (!this.props.analytics.safeWorst.loading.read) {
			this.props.fetchSafeWorst()
		}
	}

	fuse(data) {
		// const opts = {
		// 	threshold: 0,
		// 	shouldSort: true,
		// 	includeScore: true,
		// 	minMatchCharLength: 4,
		// 	keys: ['trigger', 'lag'],
		// }
		// const fuse = new Fuse(data, opts)
		// const res = this.state.searchVal
		// 	? fuse.search(this.state.searchVal).reduce((acc, res) => {
		// 			console.log(res)
		// 			acc.push(res.item)
		// 			return acc
		// 	  }, [])
		// 	: data
		// return res

		return this.state.searchVal
			? data.reduce((acc, item) => {
					if (item?.trigger?.toLowerCase()?.includes(this.state.searchVal?.toLowerCase())) {
						acc.push(item)
					}
					return acc
			  }, [])
			: data
	}

	render() {
		const { analytics } = this.props
		const safestTriggerData = this.fuse(analytics.safeWorst.best)
		const worstTriggerData = this.fuse(analytics.safeWorst.worst)
		const safe = safestTriggerData
		const worst = worstTriggerData
		const lastModified = analytics?.safeWorst?.lastModified
		const isLoading = analytics.loading.read

		return (
			<>
				<LinearProgress visible={isLoading} color="primary" />
				{lastModified ? (
					<Root className={classes.container}>
						<Paper className={classNames(classes.paper, classes.searchBarTop)}>
							<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
								<Toolbar>
									<Grid container spacing={2} alignItems="center">
										<Grid item>
											<SearchIcon className={classes.block} color="inherit" />
										</Grid>
										<Grid item xs>
											<TextField
												fullWidth
												value={this.state.searchVal}
												onChange={(event) => {
													this.setState({ searchVal: event.target.value })
												}}
												placeholder="Search by trigger"
												InputProps={{
													disableunderline: 'true',
													className: classes.searchInput,
													inputProps: {
														className: classes.compactSearch,
													},
												}}
											/>
										</Grid>

										<Grid item>
											<Tooltip arrow title="Reload" aria-label="reload">
												<IconButton color="inherit" onClick={this.hydrateData} size="large">
													<RefreshIcon className={classes.block} color="inherit" />
												</IconButton>
											</Tooltip>
										</Grid>
									</Grid>
								</Toolbar>
							</AppBar>
							<Grid container>
								<Grid
									item
									xs={12}
									style={{
										textAlign: 'end',
									}}
								>
									<Typography variant="overline" display="block">
										Last Fetched: {moment(lastModified).calendar()}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item sm={6} xs={12}>
									<SafeWorstTriggers
										title={'Safest Foods / Factors'}
										tooltip={
											"These are the triggers we've predicted have improved your symptoms given relative metabolic time intervals"
										}
										triggers={safe}
										defaultOrder="desc"
										colorRange={['#ccc', 'green']}
									/>
								</Grid>
								{/* <Divider orientation="vertical" flexItem absolute /> */}
								<Grid item sm={6} xs={12}>
									<SafeWorstTriggers
										title={'Worst Triggers'}
										tooltip={
											"These are the triggers we've predicted make your symptoms worse given relative metabolic time intervals"
										}
										triggers={worst}
										defaultOrder="asc"
										colorRange={['red', '#ccc']}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Root>
				) : analytics?.safeWorst?.lastFetched ? (
					<Content
						title={'Not enough logging data'}
						subtitle={'Keep logging your inputs and outputs in order to have analysis generated'}
					/>
				) : (
					<div className={classes.loadingContainer}>
						<Grid container justifyContent="center">
							<CircularProgress disableShrink={true} />
						</Grid>
					</div>
				)}
			</>
		)
	}
}

AnalyticsContent.propTypes = {
	analytics: PropTypes.shape({
		safeWorst: PropTypes.shape({
			lastFetched: PropTypes.number,
			best: PropTypes.array,
			worst: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchSafeWorst: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics }) => {
	return {
		analytics,
	}
}

export default compose(connect(mapStateToProps, actions))(AnalyticsContent)
