import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import Fuse from 'fuse.js'
import Content from '@components/Content'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import StoolLabelsTimeseries from './StoolLabelsTimeseries'
import { cacheDuration } from '../../../../../environment'

const PREFIX = 'StoolLabelsTimeseries'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	roleContainer: `${PREFIX}-roleContainer`,
	searchBarTop: `${PREFIX}-searchBarTop`,
	container: `${PREFIX}-container`,
	loadingContainer: `${PREFIX}-loadingContainer`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	tableWrapper: `${PREFIX}-tableWrapper`,
	compactSearch: `${PREFIX}-compactSearch`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.paper}`]: {
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.searchBarTop}`]: {
		marginBottom: '24px',
	},

	[`&.${classes.container}`]: {
		padding: '20px 36px',
	},
	[`& .${classes.loadingContainer}`]: {
		padding: '5px 36px',
	},

	[`& .${classes.contentWrapper}`]: {
		marginTop: '24px',
	},

	[`& .${classes.tableWrapper}`]: {
		padding: '0 16px',
	},

	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}))

class AnalyticsContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {}

	componentDidMount = () => {
		const lastFetched = this.props.analytics.stool_labels_timeseries.lastFetched
		if (!lastFetched) {
			const data = this.props.analytics.stool_labels_timeseries?.data || []
			if (!data.length) {
				this.hydrateData()
			}
		} else if (lastFetched + cacheDuration <= new Date().getTime()) {
			this.hydrateData()
		}
	}

	hydrateData = () => {
		// if (!this.props.analytics.stool_labels_timeseries.loading.read) {
		this.props.fetchStoolLabels()
		this.props.getBowels({
			from: '2017-01-01T00:00:00.000Z',
			to: new Date().toISOString(),
		})
	}

	render() {
		const { analytics } = this.props
		const timeSeriesData = analytics.stool_labels_timeseries.data || []
		const lastFetched = analytics.stool_labels_timeseries.lastFetched
		return timeSeriesData.length ? (
			<Root className={classes.container}>
				{/* <Paper className={classNames(classes.paper, classes.searchBarTop)}> */}
				<Grid container>
					<Grid
						item
						xs={12}
						style={{
							padding: 5,
							paddingRight: 10,
							textAlign: 'end',
						}}
					>
						Last Generated: {moment(lastFetched).calendar()}
					</Grid>
				</Grid>
				<Grid container spacing={2} style={{ marginTop: 10 }}>
					<Grid item xs={12}>
						<StoolLabelsTimeseries />
					</Grid>
				</Grid>
				{/* </Paper> */}
				{/* <Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={classes.contentWrapper}>
							<div>
								
							</div>
						</Paper>
					</Grid>
				</Grid> */}
			</Root>
		) : analytics?.stool_labels_timeseries?.lastFetched ? (
			<Content
				title={'Not enough logging data'}
				subtitle={'Keep logging bowel movements with the camera in order to have analysis generated'}
			/>
		) : (
			<div className={classes.loadingContainer}>
				<Grid container justifyContent="center">
					<CircularProgress disableShrink={true} />
				</Grid>
			</div>
		)
	}
}

AnalyticsContent.propTypes = {
	analytics: PropTypes.shape({
		stool_labels_timeseries: PropTypes.shape({
			lastFetched: PropTypes.number,
			data: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchStoolLabels: PropTypes.func.isRequired,
	getBowels: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics }) => {
	return {
		analytics,
	}
}

export default compose(connect(mapStateToProps, actions))(AnalyticsContent)
