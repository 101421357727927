import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'

import { withTranslation } from 'react-i18next'
import moment from 'moment'
import StoolFrequencyWidget from './Widgets/StoolFrequencyWidget'
import StoolDistributionWidget from './Widgets/StoolDistributionWidget'
import StoolBarChartWidget from './Widgets/StoolBarChartWidget'

const PREFIX = 'StoolLabelsTimeseries'

const classes = {
	paper: `${PREFIX}-paper`,
	miniIcon: `${PREFIX}-miniIcon`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.paper}`]: {
		margin: 'auto',
		overflow: 'hidden',
		padding: '10px',
	},

	[`& .${classes.miniIcon}`]: {
		// color: red600,
		marginRight: '10px',
	},
}))

const METRICS = {
	bristol: 7,
}

class StoolLabelsTimeseries extends Component {
	constructor(props) {
		super(props)
	}

	// static getDerivedStateFromProps(props, state) {
	// 	return {
	// 		...state,
	// 		currentDay: moment(props.summary.attentionDate).format('YYYY-MM-DD'),
	// 		currentDate: props.summary.attentionDate,
	// 		groupedByDate: props.summary.daysEvents,
	// 	}
	// }

	state = {}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}

	render() {
		const { bowel, analytics } = this.props
		const normalizedTrend =
			analytics.stool_labels_timeseries.data &&
			analytics.stool_labels_timeseries.data.map((entry) => {
				delete entry.occurred_at
				delete entry.uuid
				return Object.keys(entry).reduce((acc, item) => {
					if (METRICS[item]) {
						acc[item] = (entry[item] / METRICS[item]) * 100
					} else {
						acc[item] = entry[item]
					}
					return acc
				}, {})
			})
		const stools = bowel.data
		console.log(stools.length)
		return (
			<Root>
				<Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="flex-start">
					<Grid item xs>
						Total Bowel Movements: {stools.length}
					</Grid>
					<Grid item xs>
						Total Bowel Movements Pictures: {normalizedTrend.length}
					</Grid>
					<Grid item xs></Grid>
				</Grid>
				{/* <Grid container spacing={2} direction="row" justify="space-between" alignItems="flex-start">
                        <Grid item xs>
                            <StoolDistributionWidget data={normalizedTrend} />
                        </Grid>
                    </Grid> */}
				<Grid container spacing={2} direction="row" justifyContent="center">
					{/* <Grid item xs>
                            <StoolFrequencyWidget
                                data={[
                                    { Single: 0, Clothes: 4850, Unique: 6000 },
                                    { Single: 2000, Clothes: 1600, Unique: 5500 },
                                    { Single: 1500, Clothes: 3200, Unique: 4500 },
                                    { Single: 2500, Clothes: 2560, Unique: 5000 },
                                    { Single: 2000, Clothes: 4500, Unique: 4000 },
                                    { Single: 3000, Clothes: 5821, Unique: 4500 },
                                    { Single: 2500, Clothes: 5821, Unique: 3500 },
                                    { Single: 3500, Clothes: 5821, Unique: 4000 },
                                    { Single: 3000, Clothes: 5821, Unique: 3000 },
                                    { Single: 4000, Clothes: 5821, Unique: 3500 },
                                    { Single: 3500, Clothes: 5821, Unique: 2500 },
                                    { Single: 4500, Clothes: 5821, Unique: 3000 },
                                    { Single: 4000, Clothes: 5821, Unique: 2000 },
                                    { Single: 5000, Clothes: 5821, Unique: 2500 },
                                    { Single: 4500, Clothes: 5821, Unique: 1500 },
                                    { Single: 5500, Clothes: 5821, Unique: 2000 },

                                    { Single: 6000, Clothes: 2200, Unique: 0 },
                                ]}
                            />
                        </Grid> */}

					<Grid item>
						<StoolBarChartWidget
							data={[
								{ name: 'Jan', uv: 3700 },
								{ name: 'Feb', uv: 3000 },
								{ name: 'Mar', uv: 2000 },
								{ name: 'Apr', uv: 2780 },
								{ name: 'May', uv: 2000 },
								{ name: 'Jun', uv: 1800 },
								{ name: 'Jul', uv: 2600 },
								{ name: 'Aug', uv: 2900 },
								{ name: 'Sep', uv: 3500 },
								{ name: 'Oct', uv: 3000 },
								{ name: 'Nov', uv: 2400 },
								{ name: 'Dec', uv: 2780 },
							]}
						/>
					</Grid>
					<Grid item>
						<StoolBarChartWidget
							data={[
								{ name: 'Jan', uv: 3700 },
								{ name: 'Feb', uv: 3000 },
								{ name: 'Mar', uv: 2000 },
								{ name: 'Apr', uv: 2780 },
								{ name: 'May', uv: 2000 },
								{ name: 'Jun', uv: 1800 },
								{ name: 'Jul', uv: 2600 },
								{ name: 'Aug', uv: 2900 },
								{ name: 'Sep', uv: 3500 },
								{ name: 'Oct', uv: 3000 },
								{ name: 'Nov', uv: 2400 },
								{ name: 'Dec', uv: 2780 },
							]}
						/>
					</Grid>
					<Grid item>
						<StoolBarChartWidget
							data={[
								{ name: 'Jan', uv: 3700 },
								{ name: 'Feb', uv: 3000 },
								{ name: 'Mar', uv: 2000 },
								{ name: 'Apr', uv: 2780 },
								{ name: 'May', uv: 2000 },
								{ name: 'Jun', uv: 1800 },
								{ name: 'Jul', uv: 2600 },
								{ name: 'Aug', uv: 2900 },
								{ name: 'Sep', uv: 3500 },
								{ name: 'Oct', uv: 3000 },
								{ name: 'Nov', uv: 2400 },
								{ name: 'Dec', uv: 2780 },
							]}
						/>
					</Grid>
				</Grid>
			</Root>
		)
	}
}

StoolLabelsTimeseries.propTypes = {
	analytics: PropTypes.shape({
		stool_labels_timeseries: PropTypes.shape({
			lastFetched: PropTypes.number,
			data: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchStoolLabels: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics, bowel }) => {
	return {
		analytics,
		bowel,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(StoolLabelsTimeseries)
