import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { lighten } from '@mui/system'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import TabBar from 'molecules/TabBar'
import { useTheme } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { LinearProgress } from '@components'
import StoolLabelsTimeseries from './StoolLabelsTimeseries'
import StoolInterventionAnalytics from './StoolInterventionAnalytics'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import moment from 'moment'

const PREFIX = 'StoolAnalytics'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => {
	return {
		[`& .${classes.paper}`]: {
			margin: 'auto',
			overflow: 'hidden',
		},
		[`& .${classes.searchBar}`]: {
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		},
		[`& .${classes.searchInput}`]: {
			fontSize: theme.typography.fontSize,
		},
		[`& .${classes.block}`]: {
			display: 'block',
		},
		[`& .${classes.addUser}`]: {
			marginRight: theme.spacing(1),
		},

		[`& .${classes.container}`]: {
			padding: '48px 36px 0',
		},
	}
})

// const tabNames = ['Overview', 'Interventions/Programs', 'Symptoms']
const tabNames = ['Self-Experiments/Programs', 'Symptoms']

function StoolAnalyticsContent(props) {
	const {} = props
	const theme = useTheme()
	const [query, setQuery] = useQueryParams({
		type: withDefault(StringParam, tabNames[0]),
	})
	const onTabChange = (newValue, tabName) => {
		setQuery({ type: tabName })
	}

	const isLoading =
		props.analytics.loading.read ||
		props.bowel.loading.read ||
		props.intervention.loading.read ||
		props.intervention.interventionCompliance.loading.read
	return (
		<Root>
			<Box sx={{ width: '100%', bgcolor: lighten(theme.palette.primary[500], 0.7) }}>
				<TabBar
					tabNames={tabNames}
					tab={query.type}
					onTabChange={onTabChange}
					textColor="primary"
					indicatorColor="primary"
					elevation={1}
				/>
			</Box>
			<LinearProgress visible={isLoading} color="primary" />
			{
				{
					'Self-Experiments/Programs': <StoolInterventionAnalytics isLoading={isLoading} />,
					Overview: <StoolLabelsTimeseries isLoading={isLoading} />,
					Symptoms: <></>,
				}[query.type]
			}
		</Root>
	)
}

StoolAnalyticsContent.propTypes = {
	Progress: PropTypes.node,
	analytics: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	bowel: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	intervention: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
		interventionCompliance: PropTypes.shape({
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
	}),
}

const mapStateToProps = ({ analytics, bowel, intervention }) => {
	return {
		analytics,
		bowel,
		intervention,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(StoolAnalyticsContent)
