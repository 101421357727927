import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'

import { withTranslation } from 'react-i18next'
import moment from 'moment'
import RadarBMChart from './Widgets/RadarBMChart'
import _Context from './_Context'

const PREFIX = 'InterventionSummary'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	block: `${PREFIX}-block`,
	item: `${PREFIX}-item`,
	itemTitle: `${PREFIX}-itemTitle`,
	itemSubTitle: `${PREFIX}-itemSubTitle`,
	searchBar: `${PREFIX}-searchBar`,
	searchBarTop: `${PREFIX}-searchBarTop`,
	compactSearch: `${PREFIX}-compactSearch`,
	buttonBase: `${PREFIX}-buttonBase`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		// margin: 'auto',
		// overflow: 'hidden',
		// paddingLeft: 15,
		// paddingRight: 15,
	},

	[`& .${classes.paper}`]: {
		padding: 10,
		width: '100%',
	},
	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	[`& .${classes.block}`]: {
		justifyContent: 'center',
		verticalAlign: 'middle',
	},
	[`& .${classes.searchBarTop}`]: {
		marginBottom: theme.spacing(1.5),
	},
	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},

	[`& .${classes.contentWrapper}`]: {
		padding: 20,
	},

	[`& .${classes.item}`]: {
		padding: 15,
	},
	[`& .${classes.itemTitle}`]: {
		fontSize: 16,
	},
	[`& .${classes.itemSubTitle}`]: {
		fontSize: 13,
	},
	[`& .${classes.buttonBase}`]: {
		width: '100%',
	},
}))

const METRICS = {
	bristol: 7,
}

class InterventionSummary extends Component {
	static contextType = _Context

	constructor(props) {
		super(props)
	}

	static getDerivedStateFromProps(props, state) {
		const now = new Date().getTime()
		const interventionStates = props.intervention?.data.reduce(
			(acc, item) => {
				if (!item.occurred_at) {
					acc.queued.push(item)
				} else {
					const occurred_at = new Date(item.occurred_at)
					if (item.completed_at && Number.isInteger(item.effect)) {
						acc.historical.push(item)
					} else if (item.completed_at && !Number.isInteger(item.effect)) {
						acc.concluding.push(item)
					} else {
						acc.active.push(item)
					}
				}
				return acc
			},
			{
				queued: [],
				active: [],
				concluding: [],
				historical: [],
			}
		)

		return {
			...state,
			scopedInterventions: interventionStates,
			relevantInterventions: [
				...interventionStates.active,
				...interventionStates.concluding,
				...interventionStates.queued,
				...interventionStates.historical,
			].filter((a) => {
				if (a.occurred_at && a.completed_at) {
					return moment(a.occurred_at).add(1, 'day').isBefore(a.completed_at)
				}
				return true
			}),
		}
	}

	state = {
		hydrated: false,
		searchVal: '',
		scopedInterventions: [],
		relevantInterventions: [],
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}
	componentDidMount = () => {
		if (!this.state.hydrated) {
			this.hydrateData()
		}
	}

	hydrateData = async () => {
		this.props.getBowels({
			from: new Date('2017-01-01T00:00:00.000Z').toISOString(),
		})
		if (!this.props.intervention.loading.read) {
			await this.props.fetchInterventions()
		}
		if (!this.props.intervention.interventionCompliance.loading.read) {
			this.state.relevantInterventions.map((interv) => {
				if (!this.props.intervention.interventionCompliance.interventionMap[interv.id]) {
					this.props.fetchInterventionCompliance({
						user_intervention_id: interv.id,
					})
				}
			})
		}
	}

	fuse(data) {
		return this.state.searchVal
			? data.reduce((acc, item) => {
					if (item?.name?.toLowerCase()?.includes(this.state.searchVal?.toLowerCase())) {
						acc.push(item)
					}
					return acc
			  }, [])
			: data
	}

	render() {
		const { bowel, analytics } = this.props
		const searchedRelevant = this.fuse(this.state.relevantInterventions)
		console.log(this.context)
		return (
			<Root className={classes.root}>
				<Paper className={classNames(classes.hidden, classes.searchBarTop)}>
					<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
						<Toolbar>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<SearchIcon className={classes.block} color="inherit" />
								</Grid>
								<Grid item xs>
									<TextField
										fullWidth
										value={this.state.searchVal}
										onChange={(event) => {
											this.setState({ searchVal: event.target.value })
										}}
										placeholder="Search by Self-Experiment"
										InputProps={{
											disableunderline: 'true',
											className: classes.searchInput,
											inputProps: {
												className: classes.compactSearch,
											},
										}}
									/>
								</Grid>

								<Grid item>
									<Tooltip arrow title="Reload" aria-label="reload">
										<IconButton color="inherit" onClick={this.hydrateData} size="large">
											<RefreshIcon className={classes.block} color="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item>
									<Tooltip arrow title="Clear" aria-label="clear">
										<IconButton
											color="inherit"
											onClick={this.context.clearInterventions}
											size="large"
										>
											<DeleteIcon className={classes.block} color="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
					<Grid container spacing={2} className={classes.contentWrapper}>
						{searchedRelevant.map((intervention, i) => {
							const compliance = (
								this.props.intervention.interventionCompliance.interventionMap[intervention.id] || []
							).reduce(
								(acc, interv) => {
									if (interv.compliance === 'true') {
										acc.complied = acc.complied + 1
									} else if (interv.compliance === 'false') {
									}
									return acc
								},
								{
									complied: 0,
								}
							)
							const totalDays =
								Math.abs(
									moment(intervention.occurred_at).diff(
										intervention.completed_at || new Date(),
										'days'
									)
								) + 1
							return (
								<Grid item xs={12} sm={6} lg={4} key={i}>
									<ButtonBase
										className={classes.buttonBase}
										onClick={() => this.context.addIntervention(intervention)}
									>
										<Paper className={classes.paper} elevation={4}>
											<Grid container spacing={2} direction="column">
												<Grid item xs className={classes.item}>
													<Grid container>
														<Grid item>
															<Typography className={classes.itemTitle}>
																{intervention.meta.friendlyName}
															</Typography>
															<Typography className={classes.itemSubTitle}>{`${moment(
																intervention.occurred_at
															).format('MMM DD YYYY')} - ${
																intervention.completed_at
																	? moment(intervention.completed_at).format(
																			'MMM DD YYYY'
																	  )
																	: 'now'
															}`}</Typography>
															<Grid container>
																<Grid item>
																	<Typography className={classes.itemSubTitle}>
																		Compliance: {compliance.complied} / {totalDays}
																	</Typography>
																	<Typography className={classes.itemSubTitle}>
																		Accuracy: High
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												<Grid item>
													<RadarBMChart
														intervention={intervention}
														start={intervention.occurred_at}
														end={intervention.completed_at}
													/>
												</Grid>
											</Grid>
										</Paper>
									</ButtonBase>
								</Grid>
							)
						})}
					</Grid>
				</Paper>
			</Root>
		)
	}
}

InterventionSummary.propTypes = {
	analytics: PropTypes.shape({
		stool_labels_timeseries: PropTypes.shape({
			lastFetched: PropTypes.number,
			data: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchStoolLabels: PropTypes.func.isRequired,
	getBowels: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics, bowel, intervention }) => {
	return {
		analytics,
		bowel,
		intervention,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(InterventionSummary)
