import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { AreaChart, Area, ResponsiveContainer, Tooltip as reTooltip, XAxis } from 'recharts'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

const PREFIX = 'StoolFrequencyWidget';

const classes = {
    paperHeader: `${PREFIX}-paperHeader`,
    miniIcon: `${PREFIX}-miniIcon`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.paperHeader}`]: {
		margin: 'auto',
		overflow: 'hidden',
		padding: '10px',
		boxShadow:
			'0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
		margin: '-20px 15px 0',
		borderRadius: 3,
		padding: 15,
	},

    [`& .${classes.miniIcon}`]: {
		// color: red600,
		marginRight: '10px',
	}
}));

function StoolFrequencyWidget(props) {
	const { } = props

	return (
        <StyledPaper>
			<div className={classes.paperHeader}>
				<div className={classes.paper}>
					<ResponsiveContainer minHeight={130}>
						<AreaChart data={props.data}>
							<Area
								type="monotone"
								dataKey="Single"
								stroke={'yellow'}
								fill={'green'}
								strokeWidth={3}
								dot={{
									fill: 'yellow',
								}}
							/>
							<XAxis dataKey="name" hide={true} />
							<reTooltip wrapperStyle={{ width: 100, backgroundColor: '#403F4C', borderRadius: 5 }} />
							<Area
								type="monotone"
								dataKey="Unique"
								stroke={'yellow'}
								fill={'green'}
								strokeWidth={3}
								dot={{
									fill: 'yellow',
								}}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
			<div className="card-content">
				<h4 className="title">Website Views</h4>
				<p className="category">Monthly Website Views Graphic</p>
			</div>
			<div className="card-footer">
				<div className="stats">
					<i className={classes.miniIcon}>access_time</i>
					updated 44 minutes ago
				</div>
			</div>
		</StyledPaper>
    );
}

export default compose( withTranslation())(StoolFrequencyWidget)
