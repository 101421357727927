import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'

import * as actions from 'actions'
import _merge from 'lodash.merge'
import ReactApexChart from 'react-apexcharts'
import { withTranslation } from 'react-i18next'
import { useTheme, styled } from '@mui/material/styles'
import { Card, CardHeader } from '@mui/material'
import moment from 'moment'

//
// ----------------------------------------------------------------------

const CHART_HEIGHT = 220
const LEGEND_HEIGHT = 40

const ChartWrapperStyle = styled('div')(({ theme }) => ({
	height: CHART_HEIGHT,
	marginTop: theme.spacing(-4),
	'& .apexcharts-canvas svg': {
		height: CHART_HEIGHT,
	},
	'& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
		overflow: 'visible',
	},
	'& .apexcharts-toolbar': {
		display: 'none',
	},
	// '& .apexcharts-legend': {
	// 	height: LEGEND_HEIGHT,
	// 	alignContent: 'center',
	// 	position: 'relative !important',
	// 	borderTop: `solid 1px ${theme.palette.divider}`,
	// 	top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
	// },
}))

// ----------------------------------------------------------------------
const COLUMNS = ['bristol', 'consistency', 'fragmentation', 'edge_fuzziness', 'volume'].sort()
const REMAP = {
	edge_fuzziness: 'fuzziness',
}
const NORMALIZE = {
	bristol: (val) => (val / 7) * 100,
}
const REV_NORMALIZE = {
	bristol: (val) => Math.ceil((val / 100) * 7),
}
const NAMED_COLUMNS = COLUMNS.map((c) => (REMAP[c] ? REMAP[c] : c))
// const CHART_DATA = [{ name: 'Baseline', data: [80, 50, 30, 40, 100, 20] }]

const GenerateData = (start, end, data) => {
	const filteredData = data.filter((event) => {
		return moment(start).isBefore(event.occurred_at) && moment(end).isAfter(event.occurred_at)
	})

	return filteredData
}

const mergeSubjectiveObjective = (data) => {
	const direct = data.reduce((ac, event) => {
		if (event._labels) {
			ac.push(
				COLUMNS.reduce((acc, property) => {
					acc[REMAP[property] ? REMAP[property] : property] = event._labels[property]
					return acc
				}, {})
			)
		}
		return ac
	}, [])
	return direct
}
const averageData = (data) => {
	const totals = data.reduce(
		(acc, item) => {
			Object.keys(item).forEach((p) => {
				acc[p] = acc[p] + item[p]
			})
			return acc
		},
		NAMED_COLUMNS.reduce((a, i) => {
			a[i] = 0
			return a
		}, {})
	)
	Object.keys(totals).forEach((total) => {
		totals[total] = totals[total] / data.length
		if (NORMALIZE[total]) {
			totals[total] = NORMALIZE[total](totals[total])
		}
	})
	return totals
}

const RadarBMChart = (props) => {
	const theme = useTheme()
	const startDate = moment(props.start)
	const endDate = moment(props.end)
	const input = GenerateData(startDate, endDate, props.bowel.data)
	if (!input.length) {
		return <></>
	}
	const processed = mergeSubjectiveObjective(input)
	const average = averageData(processed)
	const CHART_DATA = [
		{
			name: props.intervention.meta.friendlyName,
			data: Object.keys(average).reduce((acc, item) => {
				acc.push(average[item])
				return acc
			}, []),
		},
	]
	const chartOptions = _merge({
		toolbar: { show: false },
		sparkline: {
			enabled: false,
		},
		stroke: { width: 2 },
		fill: { opacity: 0.48 },
		legend: { floating: true, horizontalAlign: 'center' },
		tooltip: {
			y: {
				formatter: (a, b) => {
					if (REV_NORMALIZE[NAMED_COLUMNS[b.dataPointIndex]]) {
						return REV_NORMALIZE[NAMED_COLUMNS[b.dataPointIndex]](a)
					}
					return a.toPrecision(5)
				},
			},
		},
		yaxis: {
			min: 0,
			max: 100,
			tickAmount: 5,
			show: false,
		},
		xaxis: {
			categories: NAMED_COLUMNS,
			labels: {
				style: {
					colors: NAMED_COLUMNS.map((c) => theme.palette.text.primary),
				},
			},
		},
	})

	return (
		<ChartWrapperStyle>
			<ReactApexChart
				type="radar"
				series={CHART_DATA}
				options={chartOptions}
				height={CHART_HEIGHT + LEGEND_HEIGHT}
			/>
		</ChartWrapperStyle>
	)
}

RadarBMChart.propTypes = {
	analytics: PropTypes.shape({
		stool_labels_timeseries: PropTypes.shape({
			lastFetched: PropTypes.number,
			data: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	bowel: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	start: PropTypes.string,
	end: PropTypes.string,
	fetchStoolLabels: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics, bowel }) => {
	return {
		analytics,
		bowel,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(RadarBMChart)
