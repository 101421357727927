import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import TabBar from 'molecules/TabBar'

import { withTranslation } from 'react-i18next'
import SafeWorstTriggersContent from './SafeWorstTriggersContent'
import StoolAnalytics from './StoolAnalytics'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'

import moment from 'moment'

const PREFIX = 'AnalyticsContent'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => {
	return {
		[`& .${classes.paper}`]: {
			margin: 'auto',
			overflow: 'hidden',
		},
		[`& .${classes.searchBar}`]: {
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		},
		[`& .${classes.searchInput}`]: {
			fontSize: theme.typography.fontSize,
		},
		[`& .${classes.block}`]: {
			display: 'block',
		},
		[`& .${classes.addUser}`]: {
			marginRight: theme.spacing(1),
		},

		[`& .${classes.container}`]: {
			padding: '48px 36px 0',
		},
	}
})

// const tabNames = ['Safe Worst Triggers', 'Stool Health']
const tabNames = ['Safe Worst Triggers']

function AnalyticsContent(props) {
	const {} = props
	const [query, setQuery] = useQueryParams({
		tab: withDefault(StringParam, tabNames[0]),
	})
	const onTabChange = (newValue, tabName) => {
		setQuery({ tab: tabName })
	}
	const isLoading = props.analytics.loading.read
	return (
		<Root>
			<TabBar tabNames={tabNames} tab={query.tab} onTabChange={onTabChange} />
			{
				{
					'Safe Worst Triggers': <SafeWorstTriggersContent isLoading={isLoading} />,
					// 'Stool Health': <StoolAnalytics isLoading={isLoading} />,
				}[query.tab]
			}
		</Root>
	)
}

AnalyticsContent.propTypes = {
	analytics: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
}

const mapStateToProps = ({ analytics }) => {
	return {
		analytics,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(AnalyticsContent)
